import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Observer, Subscription } from "rxjs";
import { SideMenuService } from "src/app/modules/menus/services/sideMenu.service";
import menuData from "src/assets/data/side-menu.json";
import { SideMenu } from "../../interfaces/side-menu.interface";
@Component({
    selector: "nm-side-menu",
    templateUrl: "./side-menu.component.html",
    styleUrls: ["./side-menu.component.scss"],
})
export class SideMenuComponent implements OnInit {
    menuItems: SideMenu[] = menuData;
    expanded: boolean = false;
    expanded$: Subscription;
    @Output() isExpanded = new EventEmitter<boolean>();
    constructor(private sideMenuService: SideMenuService) {}

    ngOnInit(): void {
        this.lookForExpansion();
    }

    lookForExpansion() {
        this.expanded$ = this.sideMenuService.isExpanded.subscribe(
            (expansion) => {
                this.expanded = expansion;
            }
        );
    }
}
