<!--
File: catalog-sync.component.html
Project: catalog-cloud
File Created: Thursday, 17th February 2022 11:48:25
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Friday, 11th March 2022 18:08:24
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->

<div fxLayout="column" fxLayoutAlign="center center" fxFill class="syncro">
  <button mat-raised-button color="primary" (click)="syncro()" [disabled]="disableSync">
    {{ 'SYNC' | translate }}
  </button>
</div>

<div fxLayout="column" fxLayoutAlign="center center" fxFill class="waiter">
  <mat-progress-bar mode="indeterminate" *ngIf="loading"></mat-progress-bar>
</div>

<div
  *ngIf="syncError"
  fxFill
  fxLayout="row"
  fxLayoutAlign="space-around center"
  class="results"
>
  <span>SYNC_CONNECTION_ERROR</span>
</div>

<div
  *ngIf="result"
  fxFill
  fxLayout="row"
  fxLayoutAlign="space-around center"
  class="results"
>
  <span>{{ 'SYNC_OK' | translate }} </span>
</div>

<!-- <p *ngIf="result"> Tiempo total: {{ result.timeResults.timeTaken | number }} segundos</p> -->
