/*
 * File: dropbox.component.ts                                                  *
 * Project: catalog-cloud                                                      *
 * File Created: Friday, 1st July 2022 12:43:26                                *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 2nd December 2022 14:19:50                           *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { Component, OnInit } from '@angular/core';
import { BackupService } from '../../services/backup.service';

@Component({
  selector: 'app-dropbox',
  templateUrl: './dropbox.component.html',
  styleUrls: ['./dropbox.component.scss']
})
export class DropboxComponent implements OnInit {

  constructor(private backupService:BackupService) { }

  ngOnInit(): void {
  }

  getDropBoxToken(){
    this.backupService.authDropbox().subscribe(url => {
      window.open(url);
    })
  }
}
