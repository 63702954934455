/*
 * File: auth.model.ts                                                         *
 * Project: @nousmedis/syncwall-frontend                                       *
 * File Created: Friday, 25th November 2022 13:19:33                           *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 21st February 2023 12:49:08                         *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2023 Nousmedis, CeGe                                       *
 */

import { Injectable } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { ApiService } from "../../api/services/api.service";
import { IUser } from "../interfaces/user.interface";
import jwt_decode from "jwt-decode";
import { environment } from "src/environment";
import { Subject, firstValueFrom } from "rxjs";

export class AuthModel {
  user: IUser;
  private jwtCookie = environment.app_cookie_name;
  baseUrl = environment.auth_server;
  endpoints = {
    login: this.baseUrl + "users/login",
    refresh: this.baseUrl + "users/jwt-refresh",
    getRoles: this.baseUrl + "app-user/user/",
    getAppByAppId: this.baseUrl + "apps/idapp/",
    getRoleById: this.baseUrl + "roles/search/",
  };

  changeCookie: Subject<boolean> = new Subject<boolean>();

  constructor(private api: ApiService, private cookie: CookieService) {}

  public decodeUserId(token: string) {
    if (token) {
      var jwt_data: any = jwt_decode(token);
      return jwt_data["id"];
    } else {
      return undefined;
    }
  }

  public decodeToken(token: string) {
    return jwt_decode(token);
  }

  public isExpired(jwt: string) {
    return this.decodeToken(jwt);
  }

  public setJwsTokenCookie(token: any, refresh_token: string) {
    let data = JSON.stringify({ token: token.token });
    // alert(token)
    this.cookie.set(
      this.jwtCookie,
      token,
      0,
      "/",
      environment.base_domain,
      true
    );
  }

  public isLogged(): boolean {
    if (this.cookie.check(this.jwtCookie)) {
      try {
        let token = JSON.parse(this.cookie.get(this.jwtCookie))["token"];
        let id: string = this.decodeUserId(token);
        if (id.length > 1) {
          //this.logoOut.next(false);
          return true;
        } else return false;
      } catch (e) {
        return false;
      }
    } else return false;
  }

  public setUser() {
    if (this.cookie.check(this.jwtCookie)) {
      this.user = JSON.parse(this.cookie.get(this.jwtCookie));
    } else {
      //  this.reset();
    }
    return this.user;
  }

  public getCurrentUser(): IUser | undefined {
    if (!this.cookie.check(this.jwtCookie)) {
      return undefined;
    }
    let cookie =""
    cookie = this.cookie.get(this.jwtCookie);
    console.log("Cookie",cookie)
    
    let token: any = this.decodeToken(cookie);
    if (typeof token.data == "string") {
      return JSON.parse(token.data);
    } else return token.data;
  }

  public tryLogin(user: string, pass: string) {
    let data = {
      username: user,
      password: pass,
    };
    return this.api.post(this.endpoints.login, data);
  }

  public refreshToken() {
    let data = localStorage.getItem("refresh_token");
    return this.api.post(this.endpoints.refresh, { body: data });
  }

  public resetCookie() {
    if (this.cookie.check(this.jwtCookie)) {
      this.cookie.delete(this.jwtCookie);
    }
    localStorage.clear();
    this.changeCookie.next(false);
  }

  public logOut() {
    this.user = undefined;
    this.resetCookie();
  }

  public async getRole(idUser: string, appId: string) {
    let app = await firstValueFrom(
      this.api.get(this.endpoints.getAppByAppId + appId)
    );
    if (!app) return Promise.reject("App not found");
    let userApp = await firstValueFrom(
      this.api.getFullUrl(
        this.endpoints.getRoles + app.id + "/" + idUser
      )
    );
    if (!userApp) return Promise.reject("User not found on the app");
    let role = await firstValueFrom(
      this.api.getFullUrl(this.endpoints.getRoleById + userApp.idRole)
    );
    if (!role) return Promise.reject("Role not found on the app for the user");
    return Promise.resolve(role);
  }
}
