/*
 * File: catalog-action-menu.component.ts                                      *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 11th March 2022 16:31:19                             *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */




import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-catalog-action-menu',
  templateUrl: './catalog-action-menu.component.html',
  styleUrls: ['./catalog-action-menu.component.scss']
})
export class CatalogActionMenuComponent implements OnInit {
  @Input() id:string;


  // icon variables
  icon_collapsed = 'fa-plus-circle'
  icon_expanded = 'fa-minus-circle'
  icon_delete = 'fa-trash';
  icon_duplicate = 'fa-clone';
  icon_edit = 'fa-edit';
  icon_properties = 'fa-sliders-h';
  icon_json ='fa-file-code';
  icon_indesign = 'fa-file-image';
  icon_pdf = 'fa-file-pdf';


  constructor() { }

  ngOnInit(): void {
  }

}
