/*
 * File: catalog-sync.component.ts                                             *
 * Project: catalog-cloud                                                      *
 * File Created: Tuesday, 28th December 2021 13:55:07                          *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 2nd December 2022 14:42:33                           *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */




import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CatalogService } from '../../services/catalog.service';
import { Catalog } from '../../interfaces/catalog.interface';


@Component({
  selector: 'app-catalog-sync',
  templateUrl: './catalog-sync.component.html',
  styleUrls: ['./catalog-sync.component.scss'],
})
export class CatalogSyncComponent implements OnInit {
  @Input() idCatalog: string;
  @Output() syncFinish: EventEmitter<void> = new EventEmitter<void>();
  testConnectionShow: boolean = false;
  testConnectionIcon: string = '';
  testConnectionMessage: string = '';
  connectionStatus: boolean = false;
  catalog: Catalog;
  result: any = false;
  syncError: boolean = false;

  disableSync:boolean = false;

  loading = false;
  constructor(private catalogService: CatalogService) {}

  ngOnInit(): void {}

  async syncro() {
    this.loading = true;
    this.result = null;
    this.syncError = false;
    this.catalogService
      .syncronizeSalesLayer(this.idCatalog)
      .subscribe((syncResume) => {
        this.loading = false;
        this.result = syncResume;

        if (syncResume == null) {
          this.syncError = true;
        } else {
          this.disableSync = true;
          this.syncFinish.next();
        }
      });
  }
}
