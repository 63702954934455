<mat-list>
    <a
        mat-list-item
        *ngFor="let item of menuItems"
        [routerLink]="item.link"
        routerLinkActive="item-active"
    >
        <mat-icon
            *ngIf="item.icon"
            class="icon-menu"
            [ngClass]="{ 'only-icon': !expanded }"
            >{{ item.icon }}</mat-icon
        >

        <span *ngIf="expanded" class="text-menu">{{
            item.name | translate
        }}</span></a
    >
</mat-list>

