/*
 * File: top-breadcrumbs.component.ts                                          *
 * Project: catalog-cloud                                                      *
 * File Created: Friday, 2nd December 2022 14:06:46                            *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Wednesday, 7th December 2022 13:45:24                        *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */



import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { Observable } from "rxjs";
import { Breadcrumb } from "../../interfaces/breadcrumb.interface";
import { BreadcrumbService } from "../../services/breadcrumb.service";

@Component({
    selector: "top-breadcrumbs",
    templateUrl: "./top-breadcrumbs.component.html",
    styleUrls: ["./top-breadcrumbs.component.scss"],
})
export class TopBreadcrumbsComponent  {
  breadcrumbs$: Observable<Breadcrumb[]>;

  constructor(private readonly breadcrumbService: BreadcrumbService,
    private translate: TranslateService) {
    this.breadcrumbs$ = breadcrumbService.breadcrumbs$;
  }
}
