/*
 * File: auth-interceptor.ts                                                   *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Tuesday, 28th February 2023 21:53:51                         *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */

import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { environment } from 'src/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  jwtCookie: string = environment.app_cookie_name;

  constructor(private cookieService: CookieService) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let cookie = this.cookieService.get(this.jwtCookie);
    if (cookie.length == 0) {
      return next.handle(req);
    }
    const token = this.cookieService.get(this.jwtCookie);
    if (token) {
      const headers = req.clone({
        headers: req.headers.set('api-key', `Bearer ${token}`),
      });
      return next.handle(headers);
    } else {
      return next.handle(req);
    }
  }
}
