<!--
File: catalog-action-menu.component.html
Project: catalog-cloud
File Created: Wednesday, 22nd December 2021 12:48:17
Author: Tomás Muniesa (tomas@cege.es)
-----
Last Modified: Friday, 11th March 2022 16:30:56
Modified By: Tomás Muniesa (tomas@cege.es>)
-----
Copyright 2021 - 2022 Nousmedis, CeGe
-->


<button mat-menu-item [matMenuTriggerFor]="menu" aria-label="catalog action menu">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #menu="matMenu">

  <button mat-menu-item [routerLink]="['/catalog',id]" >
    <mat-icon fontSet="fa" fontIcon="{{icon_edit}}" id="btn-edit-{{id}}" ></mat-icon>
    <span>Editar</span>
  </button>
  <button mat-menu-item>
    <mat-icon fontSet="fa" fontIcon="{{icon_duplicate}}" id="btn-duplicate-{{id}}"></mat-icon>
    <span>Duplicar</span>
  </button>
  <button mat-menu-item>
    <mat-icon fontSet="fa" fontIcon="{{icon_properties}}" id="btn-properties-{{id}}"></mat-icon>
    <span>Propiedades</span>
  </button>
  <button mat-menu-item>
    <mat-icon fontSet="fa" fontIcon="{{icon_delete}}" id="btn-delete-{{id}}"></mat-icon>
    <span>Eliminar</span>
  </button>

</mat-menu>
