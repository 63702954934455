<!--
 * File: dropbox.component.html                                                *
 * Project: catalog-cloud                                                      *
 * File Created: Friday, 1st July 2022 12:43:26                                *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 1st July 2022 12:48:43                               *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 -->



<button (click)="getDropBoxToken()">
  Autorizar a DropBox
</button>
