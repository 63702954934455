/*
 * File: environment.prod.ts                                                   *
 * Project: catalog-cloud                                                      *
 * File Created: Wednesday, 22nd December 2021 12:48:17                        *
 * Author: Tomás Muniesa (tomas@cege.es)                                       *
 * -----                                                                       *
 * Last Modified: Friday, 9th December 2022 11:21:21                           *
 * Modified By: Tomás Muniesa (tomas@cege.es>)                                 *
 * -----                                                                       *
 * Copyright 2021 - 2022 Nousmedis, CeGe                                       *
 */


export const environment = {
  production: false,
  base_url: 'https://api.dev.catalogcloud.es/api/v1',
  base_socket:'https://api.dev.catalogcloud.es/',
  mode:'prod',
  pop_duration:10,
  base_protocol:'https',
  base_domain: '.dev.catalogcloud.es',
  base_port:'443',
  base_cookie:'catalogcloud-jwt'

};
